<template>
  <div class="d-flex flex-column">
    <v-main class>
      <!-- Provides the application the proper gutter -->

      <!-- fill-height class add for center -->
      <!-- <v-skeleton-loader
        v-if="getThemeMode.isLoading"
        :loading="getThemeMode.isLoading"
        height="100vh"
        width="100%"
        color="primary"
        type="table-heading, list-item-two-line,list-item-two-line, image, table-tfoot,list-item-avatar-three-line, image, article,table-heading, list-item-two-line,list-item-two-line, image, table-tfoot,list-item-avatar-three-line, image, article"
      ></v-skeleton-loader> -->
      <div class="d-flex flex-column flex-grow-1 h-full">        
        <v-container class="pt-6" :fluid="true">          
          <!-- If using vue-router -->

          <!-- <customizer /> -->
          <transition mode="out-in">
            <router-view />
          </transition>
        </v-container>
        <div class="flex-grow-1"></div>
        <app-footer></app-footer>
      </div>




      <v-row justify="center">
          <v-dialog
            :v-model="errorPopup!=null"            
            max-width="290"
          >
            <v-card v-if="errorPopup!=null">              
              <v-card-title class="text-h5">
                {{ $t("error.anerroroccured") }}
              </v-card-title>
              <v-card-text>
                <p>
                  {{errorPopup.title}}
                </p>
                <p>
                  {{errorPopup.description}}
                </p>
                <p>
                  {{ $t("error.contactadmin_or_tryagain") }}
                </p>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>                
                <v-btn
                  color="green darken-1"
                  text
                  @click="hideErrorPopup"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

    </v-main>
  </div>
</template>

<script>
import { api, getAssetUrl } from "src/api/index";
import { mapGetters, mapActions, mapMutations } from "vuex";



export default {
  components: {
    SideBar: () => import("./Sidebar"),
    AppFooter: () => import("./Footer"),
  },
  computed: {
    ...mapGetters(["getThemeMode", "errorPopup", ""]),
  },
  data() {
    return {
      dialog: true
    }    
  },
  methods: {
    ...mapActions(["showErrorPopup", "hideErrorPopup", "initializeMyCurrentLocation"]),
    ...mapMutations(['setRefSystemServices', 'setRefSystemServiceGroups', 'setProfilePic', 'setMyLocations', 'setMyCurrentLocation']),
  },
  async mounted() {
    const rsp = await api.hud.myglobalinfo();
    this.setRefSystemServices(rsp.data.service_types);
    this.setRefSystemServiceGroups(rsp.data.service_type_groups);
    this.setProfilePic(getAssetUrl(rsp.data.profile_photo));
    this.setMyLocations(rsp.data.locations);
    this.initializeMyCurrentLocation();
  }
};
</script>

<style lang="scss">
.v-main__wrap {
  display: flex;
  flex-direction: column;
}
</style>
